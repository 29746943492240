<template>
    <div class="v-stack h-stretch gap-3" v-if="settings">
        <div class="card light border h-stretch mn">
            <div class="content h-stretch">
                <form class="v-stack gap-3 h-stretch">
                    <div class="pane-horizontal gap-3">
                        <div class="v-stack h-stretch gap-3">
                            <label class="text-left required">From</label>
                            <InputDatePicker v-model="startDate"></InputDatePicker>
                        </div>
                        <div class="v-stack h-stretch gap-3">
                            <label class="text-left required">To</label>
                            <InputDatePicker v-model="endDate"></InputDatePicker>
                        </div>
                    </div>
                    <label>{{ numberOfDays }} day(s)</label>
                    <div class="v-stack h-stretch gap-3">
                        <label class="text-left required">Type</label>
                        <select v-model="type">
                            <option value="personal">Personal</option>
                            <option value="paid">Paid</option>
                            <option value="study">Study</option>
                        </select>
                        <label>{{ availablePaid }} paid days left ({{ settings.paidDaysPerYear }} per year), {{
                                availableStudy
                        }} study
                            days left
                            ({{
                                    settings.studyDaysPerMonth
                            }} per month)</label>
                    </div>
                    <div class="v-stack h-stretch gap-3">
                        <label class="text-left">Description</label>
                        <textarea v-model="description"></textarea>
                    </div>
                    <div class="h-stack h-space-between">
                        <button class="edit" @click.prevent="goBack()">Cancel</button>
                        <button class="submit" @click.prevent="sendVacation()">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";
const moment = require("moment");
import { mapActions } from "vuex";

export default {
    components: {
        InputDatePicker
    },
    computed: {
        numberOfDays() {
            return moment(this.endDate).diff(moment(this.startDate), "day") + 1
        }
    },
    methods: {
        ...mapActions(["addVacation", "getSettings", "getAvailablePaidVacations", "getAvailableStudyVacations"]),
        goBack() {
            this.$router.go(-1)
        },
        sendVacation() {
            this.addVacation({
                startDate: this.startDate,
                endDate: this.endDate,
                type: this.type,
                description: this.description
            }).then(() => {
                this.goBack()
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    watch: {
        startDate() {
            if (moment(this.endDate).isBefore(moment(this.startDate), "day")) {
                this.endDate = this.startDate
            }
        },
        endDate() {
            if (moment(this.endDate).isBefore(moment(this.startDate), "day")) {
                this.startDate = this.endDate
            }
        }
    },
    data() {
        return {
            startDate: moment().toISOString(),
            endDate: moment().toISOString(),
            type: 'personal',
            description: "",
            settings: null,
            availablePaid: 0,
            availableStudy: 0
        }
    },
    mounted() {
        this.getSettings().then((settings) => {
            this.settings = settings
        }).catch((error) => {
            console.log(error)
        })

        this.getAvailablePaidVacations().then((amount) => {
            this.availablePaid = amount
        }).catch((error) => {
            console.log(error)
        })

        this.getAvailableStudyVacations().then((amount) => {
            this.availableStudy = amount
        }).catch((error) => {
            console.log(error)
        })
    }
}
</script>